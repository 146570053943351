module.exports = {
	siteTitle: 'Rub Ivy - Taj Blog', // <title>
	shortSiteTitle: 'Sve o kulturi, izlascima, jelu i naravno piću', // <title> ending for posts and pages
	siteDescription: 'Predstavljanje događaja, te izvještaj s istih.',
	siteUrl: 'https://www.rubivy.com',
	// pathPrefix: "",
	siteImage: 'preview.jpg',
	siteLanguage: 'en',

	/* author */
	authorName: 'Ivy Rubi',
	authorTwitterAccount: 'ivyruby',

	/* info */
	headerTitle: 'Ivy Rubi',
	headerSubTitle: 'Vaš izvjestitelj',

	/* manifest.json */
	manifestName: 'Rub Ivy - Taj Blog',
	manifestShortName: 'RubIvyBlog', // max 12 characters
	manifestStartUrl: '/index.html',
	manifestBackgroundColor: 'white',
	manifestThemeColor: '#666',
	manifestDisplay: 'standalone',

	// gravatar
	// Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
	// Replace your email adress with md5-code.
	// Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
	// gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
	gravatarImgMd5: '',

	// social
	authorSocialLinks: [
		{ name: 'github', url: 'https://github.com/' },
		{ name: 'twitter', url: 'https://twitter.com/' },
		{ name: 'facebook', url: 'http://facebook.com/' }
	]
};
